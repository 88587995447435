import moment from "moment";
import Avatar from "../../../components/Avatar";
import ViewBlockAudio from "../../../components/BlockView/blocks/ViewBlockAudio";
import ViewBlockPhoto from "../../../components/BlockView/blocks/ViewBlockPhoto";
import ViewBlockVideo from "../../../components/BlockView/blocks/ViewBlockVideo";
import { getClasses } from "../../../constants/theme";
import { makeImageUrl, parseRequest } from "../../../utils";
import { useEffect, useRef } from "react";
import { MessageSquare } from "react-feather";
import Link from "../../../components/Link";

const replaceUrlsWithLinks = (text = "")=>  {
  const expressionWithHttp =
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi;

  let regex = new RegExp(expressionWithHttp);

  try {
    return text.replace(regex, (a) => {
      if ( a.length > 40 ) {
        return `<a style="word-wrap: break-word;" href='${a}'>${a.slice(0, 40)}...</a>`;
      } else {
        return `<a style="word-wrap: break-word;" href='${a}'>${a}</a>`;
      };
      // return `<a style="word-wrap: break-word; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; max-width: 400px;" href='${a}'>${a}</a>`;
    });
  } catch (err) {
    return text;
  }
}

export default function Message(props) {
  const classes = getClasses(dynamicStyle);
  const message = props.message ?? {};
  const elm = useRef();

  useEffect(() => {
    if (elm.current, props.selected) {
      elm.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [elm.current, props.selected])

  return (
    <div ref={elm} className={classes('wrapper', props.selected && "selected", !props.isClient && !props.hideActions && "adminMessage")}>
      {!!props.hideActions === false && !props.isClient && <div className={classes("actions")}>
        <div onClick={() => props.onAnswerMessage(message)} className={classes("action")}>
          <MessageSquare size={14} color={'#7E94AA'} />
        </div>
      </div>}

      <div className={classes("message", props.answer && 'answer')}>
        <div className={classes("body", !props.isClient && "adminBody")}>
          <div className={classes("title")}></div>

          {message.message && <Message hideActions={true} answer={true} message={message.message} />}

          {message.type === "request" && <div><Link to={`/requests/${message.request._id}`}><b>#Запрос</b></Link></div>}
          {message.type === "request" ? <div className={classes("text")}>{parseRequest(message)}</div> : <div dangerouslySetInnerHTML={{__html:replaceUrlsWithLinks(message.text)}} className={classes("text")}></div>}


          {(message.attachments || []).map((attach, index) => {
            const type = attach.content_type.split('/')[0];


            if (type === "image") {
              return (
                <div key={index + ''}>
                  <ViewBlockPhoto block={attach} />
                </div>
              );
            } else if (type === "video") {
              return (
                <div key={index + ''}>
                  <ViewBlockVideo block={attach} />
                </div>
              );
            } else {
              return (
                <a href={makeImageUrl(attach)} key={index + ''}>
                  {attach.filename}
                </a>
              );
            }
          })}
          <div className={classes("data")}>
            {" "}
            {moment(message.created_at).format('HH:mm:ss DD.MM')}
          </div>
        </div>
      </div>
      {!!props.hideActions === false && props.isClient && <div className={classes("actions")}>
        <div onClick={() => props.onAnswerMessage(message)} className={classes("action")}>
          <MessageSquare size={14} color={'#7E94AA'} />
        </div>
      </div>}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    action: {
      cursor: 'pointer'
    },
    actions: {
      margin: '8px 8px',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row'
    },
    answer: {
      maxWidth: "100%!important",
      flex: 1,
      display: 'flex',
    },
    selected: {
      backgroundColor: '#0560fd38',
      borderRadius: "8px 8px 8px 8px",
    },
    data: {
      display: "flex",
      justifyContent: "flex-end",
      ...Fonts.tag,
      color: Colors.green90,
    },
    text: {
      marginBottom: 4,
      ...Fonts.tableData,
      color: Colors.n90,
      
    },
    title: {
      marginBottom: 4,
      ...Fonts.mainMenu,
      color: Colors.green90,
    },
    avatar: {},
    message: {
      display: "flex",
      marginTop: 6,
      marginBottom: 6,
      alignItems: "flex-end",
      maxWidth: '90%'

    },
    body: {
      backgroundColor: Colors.n0,
      marginRight: 4,
      marginLeft: 4,
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px 10px 10px 0px",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 2,

    },
    adminBody: {
      backgroundColor: Colors.green10,
      borderRadius: "8px 8px 0px 8px",
      maxWidth: "calc(100% - 32px)",
    },
    adminMessage: {
      justifyContent: 'flex-end'
    },
  };
};
